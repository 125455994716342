<template>
    <v-container class="py-8 px-6 pl-12" fluid>

        <tile-floating-title @submit="doSubmit" v-if="showFloating"></tile-floating-title>

        <v-row class="mt-16 following-row">
            <v-col>
                <tile-use></tile-use>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <tile-company-name></tile-company-name>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                <tile-company-intro></tile-company-intro>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <tile-company-addr></tile-company-addr>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'svc_wv',
    components: {
        'tileFloatingTitle': () => import('@/components/common/tile_floating_title.vue'),
        'tileUse': () => import('@/components/svc/wv/tile_use.vue'),
        'tileCompanyName': () => import('@/components/svc/wv/tile_company_name.vue'),
        'tileCompanyIntro': () => import('@/components/svc/wv/tile_company_intro.vue'),
        'tileCompanyAddr': () => import('@/components/svc/wv/tile_company_addr.vue')
    },
    data: () => ({
        showFloating: false
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_screen_config')
        this.$store.commit('svcVoiceIntro/setFloatingOptions', {
            title: '보이는 ARS',
            page: 'svc_screen_config',
            css: {
                pixedWidth: -25
            }
        })
        this.doSearch()
    },
    mounted() {
        this.showFloating = true
    },
    methods: {
        doSearch() {
            this.$store.dispatch('svcWv/getConfig', {
                scb: c => {
                }, fcb: err => {
                    if (this.$noSessionProc(err)) {

                    }
                }, va: this
            })
        },

        doSubmit() {
            this.$store.dispatch('openConfirm', {
                message: '현재 내용으로 저장하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.doSubmitProc()
                }
            })
        },
        doSubmitProc() {
            let va = this

            this.$store.dispatch('svcWv/doSubmit', {
                scb: () => {
                    va.$store.dispatch('openAlert', {
                        va: va,
                        message: '저장되었습니다',
                        closeCb: () => {
                            va.doSearch()
                        }
                    })
                }, fcb: (err) => {
                    va.$store.dispatch('openAlert', {
                        message: '저장을 실패 했습니다',
                        sub_message: err
                    })
                }, va: va
            })
        }
    }
}
</script>

